export interface XYCoords {
  x: number
  y: number
}
export interface Snake extends XYCoords {
  dx: number
  dy: number
  cells: XYCoords[]
  maxCells: number
}
// eslint-disable-next-line
export interface Apple extends XYCoords {
}

export const defaultSnake: (grid: number) => Snake = (grid: number) => {
  return {
    x: 160,
    y: 160,

    // snake velocity. moves one grid length every frame in either the x or y direction
    dx: grid,
    dy: 0,

    // keep track of all grids the snake body occupies
    cells: [],

    // length of the snake. grows when eating an apple
    maxCells: 4
  }
}
