





import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Snake, Apple } from '../helpers/SnakeHelper'

const makeid = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

function getRandomInt (min:number, max:number) {
  return Math.floor(Math.random() * (max - min)) + min
}

@Component
export default class SnakeComponent extends Vue {
  @Prop() private isPlayer!: boolean
  @Prop() private snake!: Snake
  @Prop() private apple!: Apple
  @Prop() public loaded!: boolean
  @Prop() private moveFunction!: (snake: Snake, apple: Apple) => void

  @Watch('loaded')
  loadedWatcher (newVal: boolean, prev: boolean): void {
    if (newVal === true) {
      if (this.isPlayer) {
        this.registerSnakeGame()
      }
      requestAnimationFrame(this.startSnakeLoop)
    }
  }

  public canvasId: string = makeid(5)

  grid = 16
  count = 0
  // @ts-ignore
  canvas: HTMLCanvasElement
  // @ts-ignore
  context: CanvasRenderingContext2D

  mounted (): void {
    this.canvas = document.getElementById(this.canvasId) as HTMLCanvasElement
    this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D
    if (this.loaded) {
      this.loadedWatcher(true, true)
    }
  }

  registerSnakeGame (): void {
    document.addEventListener('keydown', (e) => {
      // prevent snake from backtracking on itself by checking that it's
      // not already moving on the same axis (pressing left while moving
      // left won't do anything, and pressing right while moving left
      // shouldn't let you collide with your own body)

      // left arrow key
      if (e.which === 37 && this.snake.dx === 0) {
        this.snake.dx = -this.grid
        this.snake.dy = 0
      }
      // up arrow key
      else if (e.which === 38 && this.snake.dy === 0) {
        this.snake.dy = -this.grid
        this.snake.dx = 0
      }
      // right arrow key
      else if (e.which === 39 && this.snake.dx === 0) {
        this.snake.dx = this.grid
        this.snake.dy = 0
      }
      // down arrow key
      else if (e.which === 40 && this.snake.dy === 0) {
        this.snake.dy = this.grid
        this.snake.dx = 0
      }
    })
  }

  startSnakeLoop (): void {
    requestAnimationFrame(this.startSnakeLoop)

    // slow game loop to 15 fps instead of 60 (60/15 = 4)
    if (++this.count < 4) {
      return
    }

    this.count = 0
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    if (this.isPlayer) {
      // move snake by it's velocity
      this.snake.x += this.snake.dx
      this.snake.y += this.snake.dy

      // wrap snake position horizontally on edge of screen
      if (this.snake.x < 0) {
        this.snake.x = this.canvas.width - this.grid
      }
      else if (this.snake.x >= this.canvas.width) {
        this.snake.x = 0
      }

      // wrap snake position vertically on edge of screen
      if (this.snake.y < 0) {
        this.snake.y = this.canvas.height - this.grid
      }
      else if (this.snake.y >= this.canvas.height) {
        this.snake.y = 0
      }

      // keep track of where snake has been. front of the array is always the head
      this.snake.cells.unshift({ x: this.snake.x, y: this.snake.y })

      // remove cells as we move away from them
      if (this.snake.cells.length > this.snake.maxCells) {
        this.snake.cells.pop()
      }
    }

    // draw apple
    this.context.fillStyle = 'red'
    this.context.fillRect(this.apple.x, this.apple.y, this.grid - 1, this.grid - 1)

    // draw snake one cell at a time
    this.context.fillStyle = 'green'
    this.snake.cells.forEach((cell, index) => {
    // drawing 1 px smaller than the grid creates a grid effect in the snake body so you can see how long it is
      this.context.fillRect(cell.x, cell.y, this.grid - 1, this.grid - 1)
      if (this.isPlayer) {
        // snake ate apple
        if (cell.x === this.apple.x && cell.y === this.apple.y) {
          this.snake.maxCells++

          // canvas is 400x400 which is 25x25 grids
          this.apple.x = getRandomInt(0, 25) * this.grid
          this.apple.y = getRandomInt(0, 25) * this.grid
        }

        // check collision with all cells after this one (modified bubble sort)
        for (var i = index + 1; i < this.snake.cells.length; i++) {
        // snake occupies same space as a body part. reset game
          if (cell.x === this.snake.cells[i].x && cell.y === this.snake.cells[i].y) {
            this.snake.x = 160
            this.snake.y = 160
            this.snake.cells = []
            this.snake.maxCells = 4
            this.snake.dx = this.grid
            this.snake.dy = 0

            this.apple.x = getRandomInt(0, 25) * this.grid
            this.apple.y = getRandomInt(0, 25) * this.grid
          }
        }
      }
    })
    if (this.isPlayer) {
      this.moveFunction(this.snake, this.apple)
    }
  }
}
