









import { Component, Vue } from 'vue-property-decorator'
import SnakeComponent from '@/components/Snake.vue' // @ is an alias to /src
import { Snake, Apple, defaultSnake } from '../helpers/SnakeHelper'
interface User {
  id: string
  name: string
  snake: Snake
  apple: Apple
}
@Component({
  components: {
    Snake: SnakeComponent
  }
})
export default class Home extends Vue {
  id = ''
  ws: WebSocket | undefined
  wsConnected = false
  users: {[key: string]: User} = {}
  get mappedUsers () {
    return Object.entries(this.users).filter(([key, value]) => {
      return key !== this.id && value.snake !== undefined
    })
  }

  mounted (): void {
    const hn = 'https://websocket.scotsoo.me'
    const url = new URL(hn)
    url.protocol = 'wss'
    url.pathname = '/websocket'
    this.ws = new WebSocket(url.toString())
    this.ws.addEventListener('open', () => {
      console.log('websocket open')
      this.wsConnected = true
    })
    this.ws.addEventListener('message', ({ data }) => {
      const parsed = JSON.parse(data) as {
        id: string
        users: { [key: string]: User }
      }
      this.users = parsed.users
      console.log(parsed.users)
      this.id = parsed.id
    })
    this.ws.addEventListener('close', (evt: unknown) => {
      console.log('Closed websocket')
      console.log(evt)
    })
  }

  moveFunction (): void {
    if (this.ws !== undefined) {
      this.ws.send(JSON.stringify({ type: 'SNAKE:MOVE', snake: this.snake, apple: this.apple }))
    }
  }

  defaultSnake (): Snake {
    return defaultSnake(16)
  }

  apple: Apple = {
    x: 320,
    y: 320
  }

  snake: Snake = defaultSnake(16)
}
